<template>
  <ExpandedCard title="Timers" :loading="loading_items">
    <template v-slot:default>
      <v-row dense align="center" justify="center" class="my-2">
        <v-col :cols="mdAndUp ? 4 : 12">
          <v-sheet>
            <h4 class="primary--text text-center fw-900 fonst-size-13">
              Today
            </h4>
            <CustomClock v-model="isRunning" :time-string="today"></CustomClock>
          </v-sheet>
        </v-col>
        <v-col :cols="mdAndUp ? 4 : 12">
          <v-sheet>
            <h4 class="primary--text text-center fw-900 fonst-size-13">
              This Week
            </h4>
            <CustomClock v-model="isRunning" :time-string="week"></CustomClock>
          </v-sheet>
        </v-col>
        <v-col :cols="mdAndUp ? 4 : 12">
          <v-sheet>
            <h4 class="primary--text text-center fw-900 fonst-size-13">
              This Month
            </h4>
            <CustomClock v-model="isRunning" :time-string="month"></CustomClock>
          </v-sheet>
        </v-col>
      </v-row>
      <VueTable
        :items="items"
        :show-top-header="false"
        :headers="headers"
        :show-row-actions="false"
        icon="mdi-clock"
        :loading="loading_items"
        empty-text="No timers yet"
        :no-more-data="!has_more_items"
        :show-select="false"
        @load-more="loadMoreItems"
      >
        <template v-slot:row-slot="{ item }">
          <td>{{ item.created_at | utc_to_local('lll') }}</td>
          <td v-if="item.stop">
            {{ item.stop.created_at | utc_to_local('lll') }}
          </td>
          <td v-else>Ongoing</td>
          <td v-if="item.stop">{{ item.stop.properties.total_time }}</td>
          <td v-else>Ongoing</td>
          <td>{{ item.status | ucwords }}</td>
        </template>
      </VueTable>
    </template>
  </ExpandedCard>
</template>

<script>
import ExpandedCard from '../components/ExpandedCard.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import CustomClock from '../../DashboardContent/components/CustomClock.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  mixins: [fetch_services],
  components: { ExpandedCard, VueTable, CustomClock },
  created() {
    this.loadItems(`api/timer/history/global`, true, null, null)
    this.getUserTimerStats()
  },
  data: (vm) => ({
    headers: [
      { text: 'From', align: 'left', sortable: false },
      { text: 'To', align: 'left', sortable: false },
      { text: 'Duration', align: 'left', sortable: false },
      { text: 'Status', align: 'left', sortable: false }
    ]
  }),
  computed: {
    ...mapGetters('timers', ['globalTimerRequesting']),
    isRunning: {
      get() {
        return this.$store.getters['timers/globalTimerIsRunning']
      },
      set(val) {
        this.$store.commit('timers/setGlobalTimerIsRunning', val)
      }
    },
    today: {
      get() {
        return this.$store.getters['timers/todayTimers']
      },
      set(val) {
        this.$store.commit('timers/setTodayTimers', val)
      }
    },
    week: {
      get() {
        return this.$store.getters['timers/weekTimers']
      },
      set(val) {
        this.$store.commit('timers/setWeekTimers', val)
      }
    },
    month: {
      get() {
        return this.$store.getters['timers/monthTimers']
      },
      set(val) {
        this.$store.commit('timers/setMonthTimers', val)
      }
    }
  },
  methods: {
    ...mapActions('timers', ['getUserTimerStats'])
  }
}
</script>

<style lang="scss" scoped></style>
